import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { PostDetail, PostItems } from "../components/post"

const PostTemplate = ({ data: { post, relatedPosts } }) => {
  return (
    <Layout post={post} page={post.frontmatter.slug}>
      <SEO
        title={`${post.frontmatter.title}${
          post.frontmatter.title2 ? ` ${post.frontmatter.title2}` : ""
        }`}
        description={post.frontmatter.description}
        image={post.frontmatter.socialImage.publicURL}
        ogType="article"
      />
      <PostDetail post={post} />

      <footer className="content-container post-byline">
        <div className="content">
          <p>
            Find Your Weirdos was designed and built by the friendly weirdos at <a href="https://andyet.com">&yet</a>. If your company needs help with your next project, <a href="https://andyet.com/contact">reach out</a>!
          </p>
        </div>
      </footer>

      <aside className="additional-reading">
        <header>
          <div className="content-container">
            <h4>Additional Reading</h4>
          </div>
        </header>
        <PostItems posts={relatedPosts} />
      </aside>
      <div className="pre-footer"></div>
    </Layout>
  )
}

export default PostTemplate

export const query = graphql`
  query($slug: String, $relatedPosts: [String]) {
    post: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      ...PostFields
    }

    relatedPosts: allMarkdownRemark(
      filter: { frontmatter: { slug: { in: $relatedPosts } } }
    ) {
      edges {
        node {
          ...PostFields
        }
      }
    }
  }
`
